<template>
  <div>
    not found
  </div>
</template>
<script>
export default {
  name: 'PageNotFound'
}
</script>
